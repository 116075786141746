<template>
  <v-card class="full-page d-flex flex-column mt-3 mx-3 shadow-1">
    <!-- Search Bar and Feedback Button -->
    <v-card-title>
      <!-- Search Bar -->
      <v-text-field
        v-model="filters.search"
        class="kanban-filter"
        clearable
        dense
        height="50"
        hide-details
        outlined
        placeholder="Search by Title, Tagline, Creator…"
      >
        <v-icon
          slot="append"
          class="ml-1"
        >
          search
        </v-icon>
        <v-badge
          slot="append"
          :content="filterCount"
          :value="filterCount"
        >
          <v-btn
            icon
            @click="showFilters = !showFilters"
          >
            <v-icon
              :color="showFilters ? 'accent' : undefined"
              class="pa-1"
              v-text="'$filters'"
            />
          </v-btn>
        </v-badge>
      </v-text-field>
    </v-card-title>

    <!-- Advanced Filters -->
    <v-card-subtitle v-if="showFilters">
      <!-- Filter Options -->
      <v-row
        id="kanban-filters"
        align="center"
        class="my-1"
      >
        <!-- Filters Text -->
        <v-col cols="auto">
          Filters:
        </v-col>

        <!-- Select Dropdowns -->
        <v-col
          v-for="select in SELECTS"
          :key="select.label"
        >
          <v-select
            v-model="filters[select.filter]"
            :clearable="!!filters[select.filter].length"
            :items="select.items"
            :label="filters[select.filter].length ? select.label : null"
            :menu-props="{ bottom: true, offsetY: true }"
            :no-data-text="`No ${select.label}`"
            :placeholder="`Select ${select.label}`"
            class="kanban-filter pt-0 text-no-wrap"
            dense
            hide-details
            item-text="name"
            multiple
            outlined
            return-object
          >
            <v-icon
              slot="prepend-inner"
              class="mr-1"
            >
              {{ select.icon }}
            </v-icon>

            <!-- Selected Count -->
            <template #selection="{ index }">
              <span v-if="!index">
                {{ filters[select.filter].length }} selected
              </span>
            </template>

            <!-- Select All -->
            <template
              v-if="select.items.length > 1"
              #prepend-item
            >
              <v-list-item @click="selectAllFilters(select)">
                Select All
              </v-list-item>
              <v-divider class="my-2" />
            </template>
          </v-select>
        </v-col>

        <!-- Toggle Switches -->
        <v-col cols="auto">
          <v-row>
            <v-col
              v-for="toggle in TOGGLES"
              :key="toggle.filter"
              class="pl-3 pr-4"
              cols="auto"
            >
              <v-switch
                v-model="filters[toggle.filter]"
                :label="toggle.title"
                class="mt-0 pt-0"
                hide-details
                @change.passive="toggleSwitch(toggle.filter, $event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Selected Filters -->
      <v-row no-gutters>
        <v-chip
          v-if="!filterCount"
          class="mb-2"
          disabled
        >
          No Filters selected
        </v-chip>

        <!-- Filters Chips -->
        <v-chip
          v-for="item in [...filters.units, ...filters.coaches, ...filters.campaigns]"
          :key="`${item.type}.${item.id}`"
          class="mb-2 mr-2 primary--text"
          close
          close-icon="close"
          color="blue lighten-5"
          @click:close="filters[item.type] = filters[item.type].filter((i) => i.id !== item.id);"
        >
          <v-avatar left>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-avatar>
          {{ item.name }}
        </v-chip>

        <!-- Clear Filters Chip -->
        <v-chip
          v-if="filterCount"
          class="mb-2"
          color="primary"
          outlined
          @click="resetFilters"
        >
          <v-avatar left>
            <v-icon small>
              close
            </v-icon>
          </v-avatar>
          Clear filters
        </v-chip>
      </v-row>
      <v-divider />
    </v-card-subtitle>

    <!-- Board -->
    <v-card-text class="align-content-stretch d-flex flex-grow-1 kanban-board no-gutters pb-4 pr-0">
      <v-col
        v-for="(column, index) in COLUMNS"
        :key="column.name"
        :class="['kanban-column', 'd-flex', 'flex-column', { 'ml-3': Boolean(index) }]"
      >
        <h4
          :style="{ color: column.color }"
          class="my-3 px-3"
        >
          {{ column.title }}
          <span
            v-if="kanbanProjects[column.name].length"
            class="grey--text text--lighten-1"
          >
            {{ kanbanProjects[column.name].length }}
          </span>
        </h4>

        <draggable
          :id="column.name"
          :ref="column.name"
          v-model="kanbanProjects[column.name]"
          v-scroll:[`#${column.name}`]="debouncedScroll"
          :class="[
            'kanban-list flex-grow-1 pb-2 px-2',
            { loading: isLoading.projects }
          ]"
          :group="draggableGroup(column.name)"
          :sort="false"
          filter=".unmovable"
          @end="moveProject"
        >
          <!-- Skeleton Loader -->
          <template v-if="isLoading.projects || isLoadingFilterItems">
            <v-skeleton-loader
              v-for="i in 6"
              :key="i"
              class="my-2 shadow-1"
              type="article"
            />
          </template>

          <template v-else>
            <project-card
              v-for="project in kanbanProjects[column.name].slice(0, renderLimit[column.name])"
              :key="project.id"
              :class="{
                unmovable: project.isAlumni
                  || (column.name === PHASES.pending.name && !project.creator.active)
              }"
              :project="project"
              @manage="openProject"
            />
          </template>
        </draggable>

        <p
          v-if="!isLoading.projects && !isLoadingFilterItems && !kanbanProjects[column.name].length"
          class="align-self-center grey--text no-projects-message text--darken-2"
        >
          No projects found in this phase
        </p>
      </v-col>
    </v-card-text>

    <!-- Manage Project Dialog -->
    <manage-project-dialog ref="manageProjectDialog" />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Draggable from 'vuedraggable';

import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import Parse from '@kickbox/common-util/src/parse';
import ProjectCard from '@/components/projects/ProjectCard';
import { ManageProjectDialog } from '@/components/dialogs';
import { projectService } from '@/services';
import { phaseUtils } from '@/utils';

export default {
  components: {
    Draggable,
    ManageProjectDialog,
    ProjectCard
  },

  data: () => ({
    // Filter values
    filters: {
      search: null,
      campaigns: [],
      units: [],
      coaches: [],
      overdue: false,
      alumni: false
    },
    renderLimit: {},
    showFilters: false,
    unwatchLoadingProperties: null
  }),

  computed: {
    ...mapGetters(['campaigns', 'company', 'isLoading', 'allCoaches']),

    /**
     * Returns the kanban board column definitions.
     *
     * @static
     *
     * @returns { { name: string, title: string, color: string }[] }
     */
    COLUMNS() {
      const { pending, redbox, redboxFunding, bluebox, goldbox } = phaseUtils.phases;

      return Object.freeze([
        { name: pending.name, title: `${pending.title}: In Review`, color: pending.color },
        { name: 'pendingApproved', title: `${pending.title}: Approved`, color: pending.color },
        { name: redbox.name, title: redbox.title, color: redbox.color },
        { name: redboxFunding.name, title: redboxFunding.title, color: redboxFunding.color },
        { name: bluebox.name, title: bluebox.title, color: bluebox.color },
        { name: goldbox.name, title: goldbox.title, color: goldbox.color }
      ]);
    },

    /**
     * Returns a list with all company features.
     *
     * @static
     *
     * @returns {string[]}
     */
    FEATURE_NAME() { return Object.freeze(FEATURE_NAME); },

    /**
     * Returns the different project phases with their properties.
     *
     * @static
     *
     * @returns {{ [phase]: { name: string, title: string, color: string } }}
     */
    PHASES() { return Object.freeze(phaseUtils.phases); },

    /**
     * Returns the possible dropdown filters for the logged in company admin.
     */
    SELECTS() {
      const selects = [
        // Units select
        {
          filter: 'units',
          label: 'Units',
          items: this.unitItems,
          icon: '$unit'
        },
        // Coaches select
        {
          filter: 'coaches',
          label: 'Coaches',
          items: this.coachItems,
          icon: '$coach'
        }
      ];

      // Campaigns select
      if (this.company.features[this.FEATURE_NAME.CAMPAIGNS]) {
        selects.push({
          filter: 'campaigns',
          label: 'Campaigns',
          items: this.campaignItems,
          itemText: 'title',
          icon: '$campaign'
        });
      }

      return Object.freeze(selects);
    },

    /**
     * Returns the possile toggle switches for the logged in company admin.
     */
    TOGGLES() {
      return Object.freeze([
        {
          filter: 'overdue',
          title: 'Overdue only'
        },
        {
          filter: 'alumni',
          title: 'Alumni only'
        }
      ]);
    },

    /**
     * Returns the available campaigns for filtering.
     *
     * This also adds a `No Campaign` option.
     *
     * @returns {{ id: string, name: string, type: string, icon: string }}
     */
    campaignItems() {
      const campaigns = [...this.campaigns].sort((c1, c2) => c1.title.localeCompare(c2.title));
      campaigns.unshift({ id: 'none', title: 'No Campaign' });
      return campaigns.map((c) => ({
        id: c.id,
        name: c.title,
        type: 'campaigns',
        icon: '$campaign'
      }));
    },

    /**
     * Returns the available coaches for filtering.
     *
     * If the current user has the "Coach" role, he will be removed from the selection,
     * as the projects are already pre-filtered to include him as coach.
     *
     * If the current user doesn't have the "Coach" role, a "No Coach" item is added in
     * addition to the company admins.
     */
    coachItems() {
      // Get current user data
      const user = Parse.User.current();
      const hasCoachRole = this.company.coaches.includes(user.get('email'));

      // Build coach items array
      let coaches = [...this.allCoaches].sort((c1, c2) => c1.name.localeCompare(c2.name));
      if (hasCoachRole) coaches = coaches.filter((c) => c.id !== user.id);
      else coaches.unshift({ id: 'none', name: 'No Coach' });

      // Map coach items
      return coaches.map((c) => ({
        id: c.id,
        name: c.name,
        type: 'coaches',
        icon: '$coach'
      }));
    },

    /**
     * Wraps the scroll listener with a debounce to recude function calls.
     *
     * @returns {function}
     */
    debouncedScroll() {
      return this.$lodash.debounce((event) => {
        const element = event.target;
        const scrollBottom = element.scrollHeight - (element.offsetHeight + element.scrollTop);
        if (
          this.renderLimit[element.id] < this.kanbanProjects[element.id].length
          && scrollBottom < 300
        ) this.renderLimit[element.id] += 7;
      }, 50);
    },

    /**
     * Counts the amount of enabled filters.
     *
     * @returns {number}
     */
    filterCount() {
      const { campaigns, units, coaches, overdue, alumni } = this.filters;
      return Number(campaigns.length > 0)
        + Number(units.length > 0)
        + Number(coaches.length > 0)
        + Number(overdue)
        + Number(alumni);
    },

    /**
     * Applies the selected filters and returns the remaining projects.
     *
     * @returns {Object[]}
     */
    filteredProjects() {
      // Return empty array if projects are still loading
      if (this.isLoading.projects) return [];

      // Standardize filter values
      const searchString = this.filters.search?.toLowerCase().trim() || null;
      const unitIds = this.filters.units.map((u) => u.id);
      const coachIds = this.filters.coaches.map((c) => c.id);
      const campaignIds = this.filters.campaigns.map((c) => c.id);
      const overdueOnly = Boolean(this.filters.overdue);
      const alumniOnly = Boolean(this.filters.alumni);

      // Apply filters
      return this.$store.getters.dashboardProjects.filter((p) => {
        // Filter rejected projects
        if (p.isRejected) return false;

        // Filter disabled projects
        if (p.disabled) return false;

        // Apply due and alumni filters
        if (overdueOnly && !p.phase.isDue()) return false;
        if (alumniOnly !== p.isAlumni) return false;

        // Apply search string
        if (searchString) {
          const matchTitle = p.title.toLowerCase().includes(searchString);
          const matchTagline = p.tagline.toLowerCase().includes(searchString);
          const matchCreator = p.creator.name.toLowerCase().includes(searchString);
          if (!(matchTitle || matchTagline || matchCreator)) return false;
        }

        // Apply units filter
        if (unitIds.length && !unitIds.includes(p.projectUnit && p.projectUnit.id)) return false;

        // Apply coaches filter
        if (coachIds.length) {
          if (!p.coaches.length) {
            if (!coachIds.includes('none')) return false;
          } else {
            const projectCoachIds = p.coaches.map((c) => c.id);
            if (!projectCoachIds.some((c1) => coachIds.includes(c1))) return false;
          }
        }

        // Apply campaigns filter
        if (campaignIds.length) {
          if (!p.campaign) {
            if (!campaignIds.includes('none')) return false;
          } else if (!campaignIds.includes(p.campaign.id)) return false;
        }

        return true;
      });
    },

    /**
     * Returns if the page is loading filter items.
     *
     * Filter items are coaches (company admins), campaigns and units.
     *
     * @returns {boolean}
     */
    isLoadingFilterItems() {
      return this.isLoading.campaigns || this.isLoading.units;
    },

    /**
     * Assigns the filtered projects to their respective board columns.
     *
     * @returns {{ [column]: Object[] }}
     */
    kanbanProjects() {
      // Prepare return object
      const projects = {};
      this.COLUMNS.forEach((c) => { projects[c.name] = []; });

      // Assign projects to their respective phase column
      [...this.filteredProjects]
        .sort((p1, p2) => p2.updatedAt - p1.updatedAt)
        .forEach((p) => {
          if (p.phase.name === phaseUtils.phases.pending.name && p.isApproved) {
            projects.pendingApproved.push(p);
          } else projects[p.phase.name].push(p);
        });

      return projects;
    },

    /**
     * Builds the current route query object according to the selected filters.
     */
    query() {
      const query = {};

      if (this.filters.search) query.search = this.filters.search;
      if (this.filters.units.length) query.units = this.filters.units.map((u) => u.id);
      if (this.filters.coaches.length) query.coaches = this.filters.coaches.map((c) => c.id);
      if (this.filters.campaigns.length) query.campaigns = this.filters.campaigns.map((c) => c.id);
      if (this.filters.overdue) query.overdue = true;
      if (this.filters.alumni) query.alumni = true;

      return query;
    },

    /**
     * Returns the available units for filtering.
     *
     * @returns {{ id: string, name: string, type: string, icon: string }[]}
     */
    unitItems() {
      return this.$store.getters.units.map((u) => ({
        id: u.id,
        name: u.name,
        type: 'units',
        icon: '$unit'
      }));
    }
  },

  created() {
    // Apply filters when all relevant items have been loaded
    this.unwatchLoadingProperties = this.$watch('isLoadingFilterItems', (isLoading) => {
      // Exit if still loading
      if (isLoading) return;

      // Apply filters from query
      if (Object.keys(this.$route.query).length) {
        const filters = {
          search: this.$route.query.search || null,
          units: [],
          coaches: [],
          campaigns: [],
          overdue: Boolean(this.$route.query.overdue),
          alumni: Boolean(this.$route.query.alumni)
        };

        // Apply array filters
        [
          ['units', 'unitItems'],
          ['coaches', 'coachItems'],
          ['campaigns', 'campaignItems']
        ].forEach(([filter, items]) => {
          const ids = this.$route.query[filter] || [];

          // Set each filter item by id from query
          (Array.isArray(ids) ? ids : [ids]).forEach((id) => {
            const item = this[items].find((i) => i.id === id);
            if (item) filters[filter].push(item);
          });
        });

        // Commit filters
        this.filters = filters;
      }

      // Add filter and query watchers
      this.$watch('filters', this.resetRenderLimits);
      this.$watch('query', () => {
        this.$router.replace({ name: this.$route.name, query: this.query });
      });

      // Remove watcher
      if (this.unwatchLoadingProperties) this.unwatchLoadingProperties();
    }, { immediate: true });

    // Set project display parameters and fetch projects
    this.resetRenderLimits();
    projectService.getDashboardProjects();
  },

  methods: {
    /**
     * Builds the proper draggable group for each column.
     *
     * @param {string} column The name of the phase column.
     *
     * @returns {{ name: String, put: String[] }}
     */
    draggableGroup(column) {
      const { pending } = phaseUtils.phases;
      const columns = this.COLUMNS.map((c) => c.name);
      const columnIndex = columns.indexOf(column);
      const put = column === pending.name || column === 'pendingApproved'
        ? columns.slice(0, 2)
        : columns.slice(columnIndex < 3 ? 0 : 2);
      return {
        name: column,
        put
      };
    },

    /**
     * Opens the `ManageProjectDialog` on the `Move` tab with the target phase set.
     *
     * @param {Object} event The `SortableJS` event object.
     * @see https://github.com/SortableJS/Sortable#event-object-demo
     */
    moveProject(event) {
      document.activeElement.blur();
      if (event.from.id === event.to.id) return;

      if (event.to.id === 'pendingApproved') {
        this.$refs.manageProjectDialog.openModal(event.item.id, 'approve');
      } else this.$refs.manageProjectDialog.changePhase(event.item.id, this.PHASES[event.to.id]);
    },

    /**
     * Opens the `ManageProjectDialog`.
     *
     * @param {string | [string, string | number]} params
     */
    openProject(params) {
      if (Array.isArray(params)) this.$refs.manageProjectDialog.openModal(params[0], params[1]);
      else this.$refs.manageProjectDialog.openModal(params);
    },

    /**
     * Resets the project filters to their default state.
     */
    resetFilters() {
      this.filters = {
        search: this.filters.search,
        units: [],
        coaches: [],
        campaigns: [],
        overdue: false,
        alumni: false
      };
    },

    /**
     * Resets the rendered project constraints for each phase to `7`.
     *
     * This will also reset the scroll of the different phase columns.
     *
     * @returns {{ [column: string]: 7 }}
     */
    resetRenderLimits() {
      this.COLUMNS.forEach((column) => {
        if (this.$refs[column.name]) this.$refs[column.name][0].$el.scrollTop = 0;
        this.$set(this.renderLimit, column.name, 7);
      });
    },

    /**
     * Selects all filters from a filter array.
     *
     * @param {{ filter: string, label: string, items: Object[], selectAll?: boolean }} selector
     */
    selectAllFilters(selector) {
      const items = selector.items.filter((i) => i.id !== 'none');

      if (items.some((item) => !this.filters[selector.filter].includes(item))) {
        this.filters[selector.filter] = [...items];
      }
    },

    /**
     * Toggles a filter switch.
     *
     * This ensures that if one switch is set to enabled, the other one is set to disabled.
     *
     * @param {string}  switchName The name of the switch that is toggled by the user.
     * @param {boolean} value      The value the switch is being set to.
     */
    toggleSwitch(switchName, value) {
      if (value) {
        this.TOGGLES
          .filter((t) => t.filter !== switchName)
          .forEach((t) => { this.filters[t.filter] = false; });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.full-page {
  height: calc(100vh - 164px);
}

.kanban-filter {
  min-width: 240px;

  ::v-deep .v-input__append-inner {
    align-self: center !important;
    margin-top: 0 !important;
  }
}

#kanban-filters {
  margin: 12px -8px !important;

  .col {
    min-height: 40px;
    padding: 6px 8px;
    display: flex;
    align-items: center;
  }
}

.kanban-board {
  overflow-x: auto;

  &::after {
    content: '';
    padding: 8px;
  }
}

.kanban-column {
  position: relative;
  flex-basis: 100%;
  min-width: 320px;
}

.kanban-list {
  overflow-y: auto;
  background-color: #FAFAFA;
  border: #CFDAE6 dashed 1px;
  border-radius: 4px;

  &:nth-child(3) {
    margin-top: -66px;
  }

  &.loading {
    overflow-y: hidden;
  }
}

.no-projects-message {
  position: absolute;
  margin-top: 80px;
}
</style>
