<template>
  <v-card
    :id="project.id"
    v-ripple="false"
    class="item-card mt-2"
    @click="onCardClicked"
  >
    <!-- Project Title and Edit Icon -->
    <v-card-title>
      <h4 class="item-card-title">
        {{ project.title }}
      </h4>
    </v-card-title>

    <!-- Creator Name -->
    <v-card-subtitle class="font-weight-medium pb-0 text-truncate">
      by
      <a
        v-if="project.creator.name !== 'Deleted user'"
        :href="creatorProfileUrl"
        target="_blank"
        @click.stop
      >
        {{ project.creator.name }}
      </a>
      <template v-else>
        {{ project.creator.name }}
      </template>
    </v-card-subtitle>

    <!-- Card Labels -->
    <v-card-text
      v-if="labels.length"
      class="pb-0 pt-2"
    >
      <v-chip
        v-for="(label, index) of labels"
        :key="index"
        :class="[label.textColor, { 'ml-2': !!index }, 'no-hover']"
        :color="label.color"
        label
        small
        :title="label.tooltip"
      >
        <v-icon
          v-if="label.icon"
          :color="label.iconColor"
          left
          small
        >
          {{ label.icon }}
        </v-icon>
        {{ label.text }}
      </v-chip>
    </v-card-text>

    <!-- Notes and Coaches -->
    <v-card-actions class="d-flex pb-4 pt-3 px-4">
      <!-- Notes Button -->
      <v-btn
        class="item-card-notes pl-0 pr-1 py-1"
        color="grey"
        height="auto"
        outlined
        :to="{ name: 'AdminManageProjectNotes', params: { id: project.id } }"
      >
        <v-icon
          class="mr-1"
          left
          small
        >
          $notes
        </v-icon>
        {{ project.notes.length }}
      </v-btn>
      <!-- Comments Button -->
      <v-btn
        class="item-card-notes mr-2 pl-1 pr-1 py-1"
        color="grey"
        height="auto"
        outlined
        :to="{ name: 'AdminManageProjectComments', params: { projectId: project.id } }"
      >
        <v-icon
          class="mr-1"
          left
          small
        >
          $comments
        </v-icon>
        {{ amountComments() }}
      </v-btn>

      <!-- Assigned Coaches -->
      <div class="d-flex flex-grow-1 flex-row-reverse">
        <!-- Assign Coach Button -->
        <v-btn
          class="item-card-assign-coach ml-1"
          color="grey lighten-1"
          height="24"
          icon
          title="Assign coach"
          width="24"
          @click.stop="$emit('manage', [project.id, 'coaching'])"
        >
          <v-icon small>
            add
          </v-icon>
        </v-btn>

        <!-- Assigned Coaches -->
        <template v-for="(coach, index) in project.coaches">
          <!-- Coach Avatar (first 4 or all if max 5 coaches assigned) -->
          <v-avatar
            v-if="project.coaches.length <= 5 || index < 4"
            :key="`${project.id}.${coach.id}`"
            class="ml-1"
            :size="24"
            :title="coach.name"
          >
            <v-img :src="coach.photo" />
          </v-avatar>

          <!-- Additional Coach Counter (from 6 coaches upward) -->
          <v-chip
            v-else-if="index === 4"
            :key="`${project.id}.${coach.id}`"
            class="grey--text text--lighten-1"
            color="grey lighten-4"
            small
            :title="project.coaches.slice(4).map((c) => c.name).join(',\n')"
          >
            +{{ project.coaches.length - 4 }}
          </v-chip>
        </template>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import { phaseUtils } from '@/utils';

export default {
  props: {
    project: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      PENDING: phaseUtils.phases.pending.name
    };
  },
  computed: {
    /**
     * @returns {string} A link to the project creator's profile page in Portal.
     */
    creatorProfileUrl() {
      return `${process.env.VUE_APP_PORTAL_URL}/#/user/${this.project.creator.id}`;
    },

    labels() {
      const labels = [];

      if (this.project.isAlumni) {
        // Alumni label
        labels.push({
          color: 'grey lighten-3',
          text: 'Alumni',
          textColor: 'grey--text'
        });
        return labels;
      }

      if (this.project.phase.name === this.PENDING) {
        const requestedStartingMonthEnabled = this.$store.getters.company
          .features[FEATURE_NAME.REQUESTED_STARTING_MONTH];
        // Requested Start Month label
        labels.push({
          color: this.project.phase.isDue() ? 'error' : 'grey lighten-3',
          text: this.project.startMonth.format('MMMM YYYY'),
          textColor: this.project.phase.isDue() ? undefined : 'grey--text',
          tooltip: requestedStartingMonthEnabled ? 'Requested starting month' : '',
          icon: this.project.phase.isDue() ? 'mdi-alarm' : 'mdi-calendar',
          iconColor: this.project.phase.isDue() ? undefined : 'grey'
        });
      }

      if (this.project.phase.name.includes('redbox')) {
        // Due Date label
        labels.push({
          color: this.project.phase.isDue() ? 'error' : 'grey lighten-3',
          text: this.project.phase.endDate.format('MMM DD, YYYY'),
          textColor: this.project.phase.isDue() ? undefined : 'grey--text',
          tooltip: 'Due date',
          icon: this.project.phase.isDue() ? 'mdi-alarm' : 'mdi-clock-outline',
          iconColor: this.project.phase.isDue() ? undefined : 'grey'
        });
      }

      return labels;
    }
  },

  methods: {
    onCardClicked() {
      document.activeElement.blur();
      this.$emit('manage', this.project.id);
    },
    amountComments() {
      return this.$store.getters.commentsMap.get(this.project.id) || 0;
    }
  }
};
</script>

<style scoped lang="scss">
.item-card {
  z-index: 3;

  a {
    text-decoration: none;
  }

  &:first-child {
    margin-top: 0;
  }

  &:not(.unmovable) {
    cursor: grab;
  }
}

.item-card-title {
  font-weight: 500;
  line-height: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-card-notes {
  border-color: #E0E0E0;
  min-width: auto !important;
}

.item-card-assign-coach {
  background-color: #F5F5F5;
}

@supports (display: -webkit-box) {
  .item-card-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
}
</style>
